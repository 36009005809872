import Markdown from "react-markdown"
import { Message } from "../types/Message"
import remarkGfm from 'remark-gfm'
import moment from "moment"

const AssistantMessage = ({ message }: { message: Message } = { message: {} as Message }) => {
    return (
        <div className="flex items-end w-3/4">
            <img className="w-8 h-8 m-3 rounded-full hidden md:visible" src={`${process.env.REACT_APP_STATIC_FILE_PATH}/avis_logo.png`} alt="avatar" />
            <div className="p-3 bg-purple-300 dark:bg-gray-800  mx-3 my-1 rounded-2xl rounded-bl-none sm:w-3/4 md:w-3/6">
                {/* <div className="text-xs text-gray-100 dark:text-gray-200 pb-1">
                    Assistant
                </div> */}
                <div className="text-gray-700 dark:text-gray-200 prose dark:prose-invert">
                    <Markdown remarkPlugins={[remarkGfm]}>{message.content}</Markdown>
                </div>
                <div className="text-xs text-gray-400 pt-2">
                    {moment(message.createdAt).fromNow()}
                </div>
            </div>
        </div>)
}

export default AssistantMessage



