import { useEffect } from 'react';
import Chat from './components/Chat';
import './styles/style.css';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import { SESSION_KEY } from './constants';


function App() {
  const [cookies, setCookie] = useCookies([SESSION_KEY]);
  useEffect(() => {
    if (!cookies[SESSION_KEY]) {
      setCookie(SESSION_KEY, uuidv4(), { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365)});
    }
  }, []);
  return (
    <div>
      <Chat />
    </div>
  );
}

export default App;