import React, { useEffect, useState } from 'react'
import AssistantMessage from './AssistantMessage'
import UserMessage from './UserMessage'
import { Message } from '../types/Message'
import TextareaAutosize from 'react-textarea-autosize';

const ChatBox = ({ messages, onUserMessageAdded, isOnline, authInfo }: { messages: Message[], onUserMessageAdded: any, isOnline: boolean, authInfo?: any }) => {

    const [inputBox, setInputBox] = useState<any>();
    const [chatContainer, setChatContainer] = useState<any>();

    useEffect(() => {
        setTimeout(() => {
            if (chatContainer) {
                chatContainer.scrollTop = chatContainer.scrollHeight;
            }
        }, 0);
    }, [messages, chatContainer]);

    const sendMessage = () => {
        if (inputBox.value === '') return;
        onUserMessageAdded(inputBox.value);
        inputBox.value = '';
        setTimeout(() => {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }, 0);
    }

    const onkeydown = (e: any) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            sendMessage();
            e.preventDefault();
        }
    }

    return (
        <div className="flex-grow h-full flex flex-col">
            <div className="w-full h-15 p-1 bg-purple-600 dark:bg-gray-800 shadow-md rounded-xl rounded-bl-none rounded-br-none">
                <div className="flex p-2 align-middle items-center">
                    {/* <div className="p-2 md:hidden rounded-full mr-1 hover:bg-purple-500 text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>
                    </div> */}
                    <div className="border rounded-full border-white p-1/2">
                        <img className="w-10 h-10 rounded-full" src={`${process.env.REACT_APP_STATIC_FILE_PATH}/logo192.png`} alt="avatar" />
                    </div>
                    <div className="flex-grow p-2">
                        <div className="text-md text-gray-50 font-semibold">Travis AI Assistant</div>
                        <div className="flex items-center">
                            <div className={`w-2 h-2 ${isOnline ? 'bg-green-300' : 'bg-gray-300'}  rounded-full`}></div>
                            <div className="text-xs text-gray-50 ml-1">
                                {isOnline ? 'Online' : 'Offline'}
                            </div>
                        </div>
                    </div>
                    <div className="text-md text-gray-50 font-semibold pr-2">{authInfo ? `Welcome, ${authInfo.name}` : ''}</div>
                    <div className="p-2 text-white cursor-pointer hover:bg-gray-500 rounded-full hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                        </svg>
                    </div>
                </div>
            </div>
            <div ref={(tag) => (setChatContainer(tag))} className="w-full flex-grow bg-gray-100 dark:bg-gray-900 my-2 p-2 overflow-y-auto dark:[color-scheme:dark]">
                {
                    messages.map((message, index) => {
                        return (
                            message.role === 'assistant' ? <AssistantMessage key={index} message={message} /> : <UserMessage key={index} message={message} />
                        )
                    })
                }
            </div>
            <div className="p-2 bg-gray-100 dark:bg-gray-800">
                <div className="flex items-center">
                    {/* <div className="p-2 text-gray-600 dark:text-gray-200 ">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div> */}
                    <div className="search-chat flex flex-grow p-2">
                        <TextareaAutosize onKeyDown={onkeydown} ref={(tag) => (setInputBox(tag))} className="input text-gray-700 dark:text-gray-200 xs:text-base md:text-sm focus:outline-none bg-gray-100 dark:bg-gray-800 flex-grow" placeholder="Type your message ..." maxRows={3} style={{ height: 24, resize: 'none' }} />
                        <button onClick={sendMessage} className="bg-gray-100 dark:bg-gray-800 dark:text-gray-200  flex justify-center items-center pr-3 text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatBox
