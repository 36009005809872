import { Message } from "../types/Message";
import remarkGfm from 'remark-gfm'
import Markdown from 'react-markdown'
import rehypeRaw from "rehype-raw";

const UserMessage = ({ message }: { message: Message } = { message: {} as Message }) => {
    return (
        <div className="flex justify-end">
            <div className="flex items-end w-auto bg-purple-500 dark:bg-gray-800 m-1 rounded-xl rounded-br-none md:w-auto">
                <div className="p-2">
                    <div className="text-gray-200 prose dark:prose-invert">
                        <Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>{message.content}</Markdown>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserMessage



