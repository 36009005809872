import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { AxiosError, AxiosResponse } from 'axios';

const UNAUTHORIZE_CODE = [401];

export interface ApiResponse<T = any> {
  originResponse: AxiosResponse<T>;
  data: T;
}

export interface ApiError extends Error {
  originError?: AxiosError | null;
  code?: string;
  errors: [];
  error_message: string;
  extras: [];
}

/**
 * Note that this will auto prepend our app's hostname and sets headers automatically
 */
export class BaseApiService {
  protected instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_BASE_API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  protected post<T = any>(
    url: string,
    data: any,
    options?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> {
    return this.handleRequest(
      this.instance.post(this.formatUrl(url), data, options),
    );
  }

  protected get<T = any>(
    url: string,
    options?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> {
    return this.handleRequest(this.instance.get(this.formatUrl(url), options));
  }

  protected put<T = any>(
    url: string,
    data: any,
    options?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> {
    return this.handleRequest(
      this.instance.put(this.formatUrl(url), data, options),
    );
  }

  protected delete<T = any>(
    url: string,
    options?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> {
    return this.handleRequest(
      this.instance.delete(this.formatUrl(url), options),
    );
  }

  protected download<T = any>(url: string) {
    window.open(
      process.env.REACT_APP_BASE_API_URL + this.formatUrl(url),
      'download',
    );
  }

  protected openURLonNewTab<T = any>(url: string) {
    window.open(process.env.REACT_APP_BASE_API_URL + this.formatUrl(url));
  }

  protected formatUrl(url: string) {
    return url;
  }

  protected redirectIfUnauthorized(statusCode: number) {
    return UNAUTHORIZE_CODE.includes(statusCode);
  }

  /*
    Handle generic error and transform response to app response.
    */
  private async handleRequest<T = any>(
    axiosResponse: AxiosPromise<T>,
  ): Promise<ApiResponse> {
    try {
      const response = await axiosResponse;
      return {
        data: response.data,
        originResponse: response,
      };
    } catch (error: any) {
      let apiError: ApiError = {
        name: 'NETWORK_ERROR',
        originError: null,
        code: 'UNKNOWN_ERROR',
        error_message:
          "Errror occured while processing your request. Please try again later.",
        extras: [],
        errors: [],
        message: '',
      };
      // apiError.originError =  error;
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const data = error.response.data;
        if (data) {
          if (this.redirectIfUnauthorized(data.status_code)) {
            window.location.replace('/login');
          } else {
            apiError.errors = data.errors;
            apiError.message =
              data.message || 'There is something wrong in server!';
            apiError.code = data.code;
            apiError.extras = data.extras;
          }
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        apiError.code = 'NO_RESPONSE';
        apiError.message = 'Unable to connect to server';
      } else {
        // Something happened in setting up the request that triggered an Error
        apiError.code = 'RUNTIME_ERROR';
        apiError.message = error.message;
      }
      throw apiError;
    }
  }
}
