import { useEffect, useState } from 'react'
import ChatBox from './ChatBox';
import { socket } from '../socket';
import WsChannelNameBuilder from '../types/WsChannelNameBuilder';
import { v4 as uuidv4 } from 'uuid';
import { MessageService } from '../services/chat.service';
import { Message } from '../types/Message';
import { SESSION_KEY } from '../constants';
import { useCookies } from 'react-cookie';
import LoadingScreen from './LoadingScreen';

const Chat = () => {
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [messages, setMessages] = useState<Message[]>([]);
    const channelName = WsChannelNameBuilder.create().privateChat().get();
    const [chatContainer, setChatContainer] = useState<any>();
    const [authInfo, setAuthInfo] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [cookies] = useCookies([SESSION_KEY]);

    const onMessageReceived = (value: any) => {
        console.log(value + "auth");
        setMessages((messages: any[]) => [...messages, value]);
        console.log(messages);
    }

    const onAuthMessageReceived = (value: any) => {
        setAuthInfo(value);
    }

    const onUserMessageAdded = (messageText: any) => {
        console.log(messageText + " --user");
        const message: Message = { id: uuidv4(), role: 'user', content: messageText, createdAt: new Date().toLocaleTimeString() }
        socket.timeout(5000).emit(channelName, {
            identifier: cookies[SESSION_KEY],
            content: message.content,
            agentKey: "TravisAI"
        } , () => {});
        setMessages((messages: any[]) => [...messages, message]);
        console.log(messages);
    }

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on(channelName, onMessageReceived);
        socket.on(WsChannelNameBuilder.create().auth().get(), onAuthMessageReceived);

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off(channelName, onMessageReceived);
            socket.off(WsChannelNameBuilder.create().auth().get(), onAuthMessageReceived);
        };
    }, []);

    const fetchMessages = async () => {
        const response = await MessageService.getMessages(cookies[SESSION_KEY]);
        let transformedMesssages = response.data.data.map((message: any) => {
            return {
                id: message.id,
                role: message.role,
                content: message.content,
                createdAt: message.createdAt
            }
        } );  
        setMessages(transformedMesssages);
    }

    const getAuth = async () => {
        console.log("call auth");
        socket.timeout(5000).emit(WsChannelNameBuilder.create().auth().get(), {
            identifier: cookies[SESSION_KEY],
        } , () => {});
    }

    useEffect(() => {
        fetchMessages();
        console.log("chat loaded");
        (window as any).top.postMessage({event: 'chat:loaded'}, '*');
        setIsLoading(false);
        // getAuth();
    }, []);

    return isLoading ? <LoadingScreen /> : <div className="">
        <div className="flex bg-white dark:bg-gray-900">
            <div className="flex-grow  p-2 rounded-md" style={{ height: `calc(100vh - ${window.scrollY + chatContainer ? chatContainer.getBoundingClientRect().top: 0}px)` }} ref={el => setChatContainer(el)}>
                <ChatBox messages={messages} onUserMessageAdded={onUserMessageAdded} isOnline={isConnected} authInfo={authInfo} />
            </div>
        </div>
    </div>
}

export default Chat
