export default class WsChannelNameBuilder {
    private channelName: string | undefined;
    private version: string =  "v1";
    private identifier: string | undefined;

    public static create(): WsChannelNameBuilder {
        return new WsChannelNameBuilder();
    }

    public get(): string {
        if (this.identifier) {
            return `${this.version}/${this.channelName}/${this.identifier}`;
        } else {
            return `${this.version}/${this.channelName}`;
        }
    }

    public setVersion(version: string): WsChannelNameBuilder {
        this.version = version;
        return this;
    }

    public privateChat(): WsChannelNameBuilder {
        this.channelName = 'private-chat';
        return this;
    }

    public auth(): WsChannelNameBuilder {
        this.channelName = 'authentication';
        return this;
    }

    public setIdentifier(identifier: string): WsChannelNameBuilder {
        this.identifier = identifier;
        return this;
    }  

    public setChannelName(channelName: string): WsChannelNameBuilder {   
        this.channelName = channelName;
        return this;
    }
}