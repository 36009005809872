import { ApiResponse, BaseApiService } from './base.service';

export class MessageAPIFactory extends BaseApiService {
  
  getMessages(identifier: string): Promise<ApiResponse> {
    return this.get(
      `/api/aichat/messages?identifier=${identifier}`,
    );
  }
}

export const MessageService = new MessageAPIFactory();
